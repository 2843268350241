import React, {useState} from "react"
import {notification, Popconfirm, Tooltip} from "../../../components/Elements";
import moment from "moment";
import {CabinObject, getAirlineLogo, TicketStatusObject} from "../../../components/utils/appUtils";
import CancelRequestModal from "../../ModalComponent/cancelRequestModal";
import {apiUrl, xTravelUrl} from "../../../settings";
import {sendEmailFxn, ticketListFxn} from "../action";
import {useDispatch} from "react-redux";

const TicketRowComponent = (props) => {
    let {ticket, index, refreshTable} = props;
    const dispatch = useDispatch()
    let {response: {FlightItinerary}, fareQuote} = ticket;
    let segment = FlightItinerary?.Segments || []
    let firstSegment = segment[0]
    // console.log(firstSegment);
    let lastSegment = segment[segment.length - 1]
    const [showModal, setShowModal] = useState({
        visible: false,
        ticket: {}
    })
    const events = {
        showCancelModal: (ticket) => {
            setShowModal({
                visible: true,
                ticket: ticket
            })
        },
        hideCancelModal: () => {
            setShowModal({
                visible: false,
                ticket: {}
            })
        },

        sendMailFxn: async (ticketId) => {
            if (ticketId) {
                await dispatch(sendEmailFxn({ticketId}));
            } else {
                notification.error({message: 'Ticket not found!'})
            }

        }
    };

    return (
        <>
            <div className="track-flights" key={index}>
                <div className="accordion"
                     id={`accordionPanelsStayOpenExample${index}`}>
                    <div className="accordion-item">
                        <h2 className="accordion-header"
                            id={`panelsStayOpen-heading${index}`}>
                            <ul className={'d-flex flex-wrap'}>
                                <li className="p-3 head-accordion manageBookingList">
                                    <h6 className="pnr">
                                        <div className={'d-flex flex-wrap'}>
                                            <div>
                                                <div className={'fw-semibold'}>
                                                    <Tooltip title={`View Invoice`}>
                                                        PNR:&nbsp;
                                                        {ticket &&
                                                        ticket.response &&
                                                        ticket.response.PNR}
                                                    </Tooltip>
                                                </div>
                                                <small className={'d-block'}>
                                                    Booking Date:&nbsp;
                                                    {ticket &&
                                                    ticket.response &&
                                                    ticket.response.FlightItinerary &&
                                                    ticket.response.FlightItinerary.InvoiceCreatedOn &&
                                                    moment(ticket.response.FlightItinerary.InvoiceCreatedOn).format('DD/MMM/YY')}
                                                </small>
                                            </div>
                                            <div className={'mt10'}>
                                                <a
                                                    href={`/response/${ticket?._id}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className={'ms-3 viewButtonDark'}>
                                                    <img
                                                        src="/images2/eye.png"
                                                        width="20"
                                                        alt="eye-icon"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </h6>
                                    <div className={'d-flex flex-wrap airLogoDiv'}>

                                        <img className="airlineLogoSize"
                                             src={firstSegment && firstSegment.Airline && firstSegment.Airline.AirlineCode &&
                                             getAirlineLogo(firstSegment.Airline.AirlineCode)
                                             } alt=""/>
                                        <h6 className="name mt10">
                                            {firstSegment && firstSegment?.Airline && firstSegment.Airline.AirlineName ? firstSegment.Airline.AirlineName : ""}
                                        </h6>

                                    </div>
                                    <ul className={'cabinClassDiv'}>
                                        <div>
                                            <h6>
                                                {firstSegment?.CabinClass &&
                                                CabinObject[firstSegment.CabinClass]}
                                                &nbsp; Class,
                                                <small className={'d-block fw-bold'}>
                                                    {firstSegment?.Origin &&
                                                    ticket.response.FlightItinerary.Segments[0].Origin.DepTime &&
                                                    moment(ticket.response.FlightItinerary.Segments[0].Origin.DepTime).format('DD MMMM, YYYY')}
                                                </small>
                                            </h6>
                                        </div>
                                    </ul>
                                    <div className="d-flex flex-wrap align-items-center">
                                        <h6 style={{
                                            justifyContent: "center",
                                            textAlign: "center",
                                        }}>
                                            <small className="d-block">
                                                {firstSegment && firstSegment.Origin && firstSegment.Origin['Airport'] && firstSegment.Origin['Airport']['CityCode'] ? firstSegment.Origin['Airport']['CityCode'] : ""}
                                                <br/>
                                                {lastSegment && lastSegment.Origin && lastSegment.Origin['DepTime'] ? moment(lastSegment.Origin['DepTime']).format('HH:mm') : ""}
                                            </small>
                                        </h6>
                                        <span className="mx-4">
                                                                <img src="images2/plane.png" alt="plane"/>
                                                              </span>
                                        <h6
                                            style={{
                                                justifyContent: "center",
                                                textAlign: "center",
                                            }}
                                        >
                                            <small className="d-block me-3">
                                                {lastSegment && lastSegment.Destination && lastSegment.Destination['Airport'] && lastSegment.Destination['Airport']['CityCode'] ? lastSegment.Destination['Airport']['CityCode'] : ""}

                                                <br/>
                                                {lastSegment && lastSegment.Destination && lastSegment.Destination['ArrTime'] ? moment(lastSegment.Destination['ArrTime']).format('HH:mm') : ""}
                                            </small>
                                        </h6>
                                    </div>
                                    <h5 className={'ConfirmedStyle'}>
                                        {ticket &&
                                        ticket.response &&
                                        ticket.response.TicketStatus &&
                                        (TicketStatusObject[ticket.response.TicketStatus]).toUpperCase()}
                                    </h5>
                                </li>
                            </ul>
                            <ul className={'lowerButtonUl flex-wrap'}>
                                <li className="p-3 head-accordion manageBookingList ">
                                    <ul>
                                        <div
                                            className="d-flex flex-wrap gradient-button justify-content-between downloadInvoiceIcon">
                                            <a
                                                href={`/booking-details?ticketId=${ticket?._id}`}
                                                target="_blank"
                                                className="fw-semibold downloadDiv downloadButtonStyle buttonFontSize"
                                            >
                                                Download Invoice
                                            </a>
                                            <div>
                                                <a
                                                    href={`/booking-details?ticketId=${ticket?._id}`}
                                                    target="_blank"
                                                    className="btn btn-primary downloadIcon"
                                                >
                                                    <img
                                                        className="iconheight2"
                                                        src="/images2/download.png"
                                                        width="20"
                                                        alt="eye-icon"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </ul>
                                    <div className={'d-flex gap-4'}>
                                        <ul>
                                            <div>
                                                <a
                                                    className={'btn btn-primary fw-semibold buttonFontSize gradient-button lg'}
                                                    href="#"
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false">
                                                    <div>
                                                        {ticket && ticket.Passengers && ticket.Passengers.length && ticket.Passengers[0]['FirstName'] || 0}
                                                        &nbsp; - &nbsp;{ticket && ticket.Passengers && ticket.Passengers.length || 0}&nbsp; Passenger(s)
                                                        &nbsp;
                                                        <i className={'bx bx-down-arrow-alt bx-sm'}/>
                                                    </div>


                                                </a>
                                                <div className={'dropdown-menu p-3'}>
                                                    {ticket &&
                                                    ticket.Passengers &&
                                                    ticket.Passengers.map((passenger, index) => {
                                                        return (
                                                            <>
                                                                <div
                                                                    className="mt-2 d-flex flex-wrap justify-content-between passengersPopup">

                                                                    <div
                                                                        className={'d-flex flex-wrap'}>
                                                                        <div className={'p-2'}>
                                                                            <img
                                                                                className={'iconheight'}
                                                                                src="/images2/avatar.png"
                                                                                width="20"
                                                                                alt="eye-icon"
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            className="mt-2 p-1">
                                                                            {passenger.FirstName} {passenger.LastName}
                                                                        </div>
                                                                    </div>
                                                                    {/* <a
                                                                        href={`/ticket-details?ticketId=${ticket?._id}&firstName=${passenger.FirstName}&lastName=${passenger.LastName}`}
                                                                        target="_blank"
                                                                        key={index}
                                                                        className="btn btn-primary ms-4"
                                                                    >
                                                                        <Tooltip
                                                                            title={`View Ticket - ${passenger.FirstName} ${passenger.LastName}`}>
                                                                            <img
                                                                                className={'iconheight'}
                                                                                src="/images2/download.png"
                                                                                width="20"
                                                                                alt="eye-icon"
                                                                            />
                                                                        </Tooltip>
                                                                    </a>*/}
                                                                    <a
                                                                        href={`${xTravelUrl}/x-travel/eTicket?ticketId=${ticket?._id}&firstName=${passenger.FirstName}&lastName=${passenger.LastName}`}
                                                                        target="_blank"
                                                                        key={index}
                                                                        className="btn btn-primary ms-4"
                                                                    >
                                                                        <Tooltip
                                                                            title={`View Ticket - ${passenger.FirstName} ${passenger.LastName}`}>
                                                                            <img
                                                                                className={'iconheight'}
                                                                                src="/images2/download.png"
                                                                                width="20"
                                                                                alt="eye-icon"
                                                                            />
                                                                        </Tooltip>
                                                                    </a>
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                    <div>
                                                        {/*   <button
                                                                            className={'btn btn-primary fw-semibold w-100 gradient-button mt-2'}>
                                                                            Download All E-Tickets
                                                                        </button>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </ul>
                                        {/*           <ul>
                                                            <div>
                                                                <div>
                                                                    <a
                                                                        className={'btn btn-primary fw-semibold buttonFontSize gradient-button'}
                                                                        href="#"
                                                                        role="button"
                                                                        data-bs-toggle="dropdown"
                                                                        aria-expanded="false"
                                                                    >
                                                                        Download All E-Tickets
                                                                    </a>
                                                                    <div className={'dropdown-menu p-3'}>
                                                                        {ticket &&
                                                                        ticket.Passengers &&
                                                                        ticket.Passengers.map((passenger, index) => {
                                                                            return (
                                                                                <>
                                                                                    <div
                                                                                        className="mt-2 d-flex flex-wrap justify-content-between passengersPopup">

                                                                                        <div className={'d-flex flex-wrap'}>
                                                                                            <div className={'p-2'}>
                                                                                                <img
                                                                                                    className={'iconheight'}
                                                                                                    src="/images2/avatar.png"
                                                                                                    width="20"
                                                                                                    alt="eye-icon"
                                                                                                />
                                                                                            </div>
                                                                                            <div
                                                                                                className="mt-2 p-1">
                                                                                                {passenger.FirstName} {passenger.LastName}
                                                                                            </div>
                                                                                        </div>
                                                                                        <a
                                                                                            href={`/ticket-details?ticketId=${ticket?._id}&firstName=${passenger.FirstName}&lastName=${passenger.LastName}`}
                                                                                            target="_blank"
                                                                                            key={index}
                                                                                            className="btn btn-primary ms-4"
                                                                                        >
                                                                                            <Tooltip
                                                                                                title={`View Ticket - ${passenger.FirstName} ${passenger.LastName}`}>
                                                                                                <img
                                                                                                    className={'iconheight'}
                                                                                                    src="/images2/download.png"
                                                                                                    width="20"
                                                                                                    alt="eye-icon"
                                                                                                />
                                                                                            </Tooltip>
                                                                                        </a>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })}
                                                                        <div>
                                                                            <button
                                                                                className={'btn btn-primary fw-semibold w-100 gradient-button mt-2'}>
                                                                                Download All E-Tickets
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </ul>
                                                        <ul>
                                                            <div>
                                                                <button
                                                                    className={'btn btn-primary fw-semibold buttonFontSize gradient-button'}>
                                                                    Edit Booking
                                                                </button>
                                                            </div>
                                                        </ul>*/}
                                        <ul>
                                            <div>
                                                <button
                                                    className={'btn btn-primary fw-semibold buttonFontSize gradient-button lg'}
                                                    onClick={() => events.showCancelModal(ticket)}
                                                >
                                                    Change Request
                                                </button>
                                            </div>
                                        </ul>
                                        <ul>
                                            <div>

                                                <Tooltip title={'Send Mail'}>
                                                    <Popconfirm
                                                        title={'Are you sure, you want to send mail?'}
                                                        onConfirm={() => {
                                                            events.sendMailFxn(ticket._id)
                                                        }}>
                                                        <a>
                                                            <button
                                                                className={'btn btn-primary fw-semibold buttonFontSize gradient-button lg'}
                                                            >
                                                                Send Mail
                                                            </button>
                                                        </a>
                                                    </Popconfirm>
                                                </Tooltip>

                                            </div>
                                        </ul>
                                        {/* <ul>
                                                            <div>
                                                                <button
                                                                    className={'btn btn-primary fw-semibold buttonFontSize gradient-button'}>
                                                                    Share
                                                                </button>
                                                            </div>
                                                        </ul>*/}
                                    </div>
                                </li>
                            </ul>
                        </h2>
                        {/*     <div
                            id={`panelsStayOpen-collapse${index}`}
                            className="accordion-collapse collapse"
                            aria-labelledby={`panelsStayOpen-heading${index}`}
                        >
                            <div className="accordion-body">
                                <ul>
                                    <li className="manageBookingList">
                                        <h6>
                                            {ticket &&
                                            ticket.Passengers &&
                                            ticket.Passengers.length
                                                ? ticket.Passengers.length
                                                : 0}{" "}
                                            <small className="d-block">Passengers</small>
                                        </h6>
                                        <h6>
                                            {FlightItinerary &&
                                            FlightItinerary
                                                .Segments &&
                                            FlightItinerary
                                                .Segments[0] &&
                                            FlightItinerary
                                                .Segments[0].Origin &&
                                            FlightItinerary
                                                .Segments[0].Origin.DepTime &&
                                            moment(
                                                FlightItinerary
                                                    .Segments[0].Origin.DepTime
                                            ).format("DD MMM, YY")}
                                            <small className="d-block">Departure</small>
                                        </h6>
                                        <h6>
                                            Departure Time{" "}
                                            <small className="d-block">
                                                {ticket &&
                                                ticket.response &&
                                                ticket.response.FlightItinerary &&
                                                ticket.response.FlightItinerary
                                                    .Segments &&
                                                ticket.response.FlightItinerary
                                                    .Segments[0] &&
                                                ticket.response.FlightItinerary
                                                    .Segments[0].Origin &&
                                                ticket.response.FlightItinerary
                                                    .Segments[0].Origin.DepTime &&
                                                moment(
                                                    ticket.response.FlightItinerary
                                                        .Segments[0].Origin.DepTime
                                                ).format("HH:mm A")}
                                            </small>
                                        </h6>
                                        <h6>
                                            {ticket &&
                                            ticket.response &&
                                            ticket.response.FlightItinerary &&
                                            ticket.response.FlightItinerary
                                                .Segments &&
                                            ticket.response.FlightItinerary
                                                .Segments[0] &&
                                            ticket.response.FlightItinerary
                                                .Segments[0].Destination &&
                                            ticket.response.FlightItinerary
                                                .Segments[0].Destination.ArrTime &&
                                            moment(
                                                ticket.response.FlightItinerary
                                                    .Segments[0].Destination.ArrTime
                                            ).format("DD MMM, YY")}
                                            <small className="d-block">Arrival</small>
                                        </h6>
                                        <h6>
                                            Arrival Time{" "}
                                            <small className="d-block">
                                                {ticket &&
                                                ticket.response &&
                                                ticket.response.FlightItinerary &&
                                                ticket.response.FlightItinerary
                                                    .Segments &&
                                                ticket.response.FlightItinerary
                                                    .Segments[0] &&
                                                ticket.response.FlightItinerary
                                                    .Segments[0].Destination &&
                                                ticket.response.FlightItinerary
                                                    .Segments[0].Destination.ArrTime &&
                                                moment(
                                                    ticket.response.FlightItinerary
                                                        .Segments[0].Destination.ArrTime
                                                ).format("HH:mm A")}
                                            </small>
                                        </h6>
                                        <h6>
                                            Class:{" "}
                                            <small className="d-block">Business</small>
                                        </h6>
                                        <h6>
                                            Journey Time:{" "}
                                            <small className="d-block">
                                                {ticket &&
                                                ticket.response &&
                                                ticket.response.FlightItinerary &&
                                                ticket.response.FlightItinerary
                                                    .Segments &&
                                                ticket.response.FlightItinerary
                                                    .Segments[0].Duration &&
                                                `${Math.floor(
                                                    ticket.response.FlightItinerary
                                                        .Segments[0].Duration / 60
                                                )}h ${
                                                    ticket.response.FlightItinerary
                                                        .Segments[0].Duration % 60
                                                }m`}
                                            </small>
                                        </h6>
                                    </li>
                                </ul>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </div>

            {showModal.visible && (
                <CancelRequestModal
                    visible={showModal.visible}
                    onClose={() => events.hideCancelModal()}
                    ticket={showModal.ticket}
                    onSubmit={() => {
                        events.hideCancelModal();
                        refreshTable()
                    }}
                />
            )}
        </>
    )
}
export default TicketRowComponent
