import React from "react"
import {
    displayDate,
    displayTime,
    getAirlineLogo,
    getTotalDurationSegments,
    minToHours
} from "../../../components/utils/appUtils";
import {Popover} from "../../../components/Elements";
import FlightImg from "../../../components/flightImg";
import AllStopsDetailsPopover from "../components/AllStopsDetailsPopover";
import LccStatus from "../components/LccStatus";

const FlightAddress = ({data}) => {
    return (
        <>
            <small className="d-block">
                {/*{data && data.Airport && data.Airport.AirportName ? data.Airport.AirportName : ""}*/}

            </small>
            <small className="d-block">
                {/*{data && data.Airport && data.Airport.Terminal ? `${data.Airport.Terminal}, ` : ""}*/}
                {data && data.Airport && data.Airport.CityName ? `${data.Airport.CityName} ` : ""}
                {data && data.Airport && data.Airport.AirportCode ? ` (${data.Airport.AirportCode}) ` : ""}
                {/*{data && data.Airport && data.Airport.CountryName ? data.Airport.CountryName : ""}*/}
            </small>
        </>
    )
}
const AllSegmentComponent = (props) => {
    let {allSegmentData, children} = props;
    const contentBody = (
        <>
            <table className={'table table-border'}>
                <tr>
                    <th>Airline Info</th>
                    <th>Flight Info</th>
                    <th>Dept.T</th>
                    <th>Arr.T</th>
                </tr>
                {allSegmentData && allSegmentData.length ? allSegmentData.map((item) => {
                    let {Airline, Origin, Destination} = item;
                    return (
                        <>
                            <tr>
                                <td>
                                    {Airline && Airline.AirlineCode} {Airline && Airline.FlightNumber} {Airline && Airline.FareClass}
                                </td>
                                <td className={'justify-content-between'}>
                                    {Origin ? <>
                                        {Origin.Airport && Origin.Airport.AirportCode ? Origin.Airport.AirportCode : ""}
                                        ({Origin.DepTime ? displayTime(Origin.DepTime) : ""})
                                    </> : null}
                                    &nbsp;-&nbsp;

                                    {Destination ? <>
                                        {Destination.Airport && Destination.Airport.AirportCode ? Destination.Airport.AirportCode : ""}
                                        ({Destination.ArrTime ? displayTime(Destination.ArrTime) : ""})
                                    </> : null}
                                </td>
                                <td>

                                </td>
                                <td>

                                </td>
                            </tr>

                        </>
                    )
                }) : null}
            </table>
        </>
    )
    return (
        <>
            <Popover content={contentBody}>
                {children}
            </Popover>
        </>
    )
}
const OverseasNonStopComponents = (props) => {
    console.log(props,'props')
    let {data, allSegmentData, flightInfo} = props;
    let segmentStarting = allSegmentData[0];
    let segmentEnding = allSegmentData[allSegmentData.length - 1];
    return (
        <>
            <div className={''}>
                <ul>
                    <li className="p-0">
                          <span className="logo">
                            <img src={getAirlineLogo(data.AirlineCode)} alt=""/>
                          </span>
                        <h5>
                            {segmentStarting.Airline && segmentStarting.Airline.AirlineName}
                            <small className="d-block">
                                {segmentStarting.Airline && segmentStarting.Airline.AirlineCode ? segmentStarting.Airline.AirlineCode : ""}&nbsp;-&nbsp;
                                {segmentStarting.Airline && segmentStarting.Airline.FlightNumber ? segmentStarting.Airline.FlightNumber : ""}&nbsp;&nbsp;
                                {segmentStarting.Airline && segmentStarting.Airline.FareClass ? segmentStarting.Airline.FareClass : ""}
                            </small>
                            <LccStatus data={data}/>

                            {/*<small className="d-block">*/}
                            {/*    {data['IsLCC'] ? "LCC" : "Non LCC"}*/}
                            {/*</small>*/}
                        </h5>
                        <div className="d-flex align-items-center" style={{gap: 40}}>
                            <h5>
                                {segmentStarting.Origin && segmentStarting.Origin.DepTime ? <>
                                    {displayDate(segmentStarting.Origin.DepTime)}
                                </> : null}
                                {segmentStarting.Origin ? <FlightAddress data={segmentStarting.Origin}/> : null}
                            </h5>
                            <span><FlightImg/></span>
                            <h5>
                                <small className="d-block">
                                    {getTotalDurationSegments(allSegmentData)}
                                </small>
                                <AllStopsDetailsPopover allSegmentData={allSegmentData}>
                                    <p className={'non-stop-p'}>Non-Stop</p>
                                </AllStopsDetailsPopover>


                            </h5>
                            <span><FlightImg/></span>


                            <h5>
                                {segmentEnding.Destination && segmentEnding.Destination.ArrTime ? <>
                                    {displayDate(segmentEnding.Destination.ArrTime)}
                                </> : null}
                                {segmentEnding.Destination ?
                                    <FlightAddress data={segmentEnding.Destination}/> : null}
                            </h5>
                            {/*<h5>*/}
                            {/*    {segmentEnding && segmentEnding.Duration ? minToHours(segmentEnding.Duration) : ""}*/}
                            {/*    <small className="d-block">Economy Class</small>*/}
                            {/*    <small className="d-block">*/}
                            {/*        {data.IsRefundable ? "Refundable" : "Non Refundable"}*/}
                            {/*    </small>*/}
                            {/*</h5>*/}
                        </div>
                    </li>
                </ul>
            </div>
        </>
    )
}
const OverseasWithStopComponents = (props) => {
    console.log(props,'props')
    let {data, allSegmentData, flightInfo} = props;
    let {FareRules: fareRules} = data;
    let segmentStarting = allSegmentData[0];
    let segmentEnding = allSegmentData[allSegmentData.length - 1];
    let originObj = fareRules[0];
    let destinationObj = fareRules[fareRules.length - 1];
    let stopCount = allSegmentData.length - 1;

    let stopCities = allSegmentData
        .slice(0, stopCount)
        .map(segment => segment.Destination.Airport.CityName)
        .join(", ");

    const getStopLine = (numStops) => {
        return (
            <div className="stop-line">
                <div className="red-line">
                    {Array.from({length: numStops}).map((_, index) => (
                        <span key={index} className="stop-dot"></span>
                    ))}
                </div>
            </div>
        );
    };
    let stopsComponent = (
        <>
            <span><FlightImg/></span>
            <h5>
                <small className="d-block">
                    {getTotalDurationSegments(allSegmentData)}
                </small>
                {getStopLine(stopCount)}
                <AllStopsDetailsPopover allSegmentData={allSegmentData}>
                    <small>{allSegmentData.length - 1} Stop(s) via</small>
                </AllStopsDetailsPopover>
                <small className="d-block">
                    {stopCities}

                </small>

            </h5>
            <span><FlightImg/></span>
        </>
    )

    return (
        <>

            <ul>
                <li className="p-0">
                       <span className="logo">
                            <img src={getAirlineLogo(data.AirlineCode)} alt=""/>
                        </span>

                    <h5>{segmentStarting.Airline && segmentStarting.Airline.AirlineName}
                        <small className="d-block">
                            {segmentStarting.Airline && segmentStarting.Airline.AirlineCode ? segmentStarting.Airline.AirlineCode : ""}&nbsp;-&nbsp;
                            {segmentStarting.Airline && segmentStarting.Airline.FlightNumber ? segmentStarting.Airline.FlightNumber : ""}&nbsp;&nbsp;
                            {segmentStarting.Airline && segmentStarting.Airline.FareClass ? segmentStarting.Airline.FareClass : ""}
                        </small>
                        <LccStatus data={data}/>
                        {/*<small className="d-block">*/}
                        {/*    {data['IsLCC'] ? "LCC" : "Non LCC"}*/}
                        {/*</small>*/}
                    </h5>

                    <div className="d-flex align-items-center"
                         style={{gap: '40px'}}>
                        <h5>
                            {segmentStarting.Origin && segmentStarting.Origin.DepTime ? <>
                                {displayDate(segmentStarting.Origin.DepTime)}
                            </> : null}
                            {segmentStarting.Origin ? <FlightAddress data={segmentStarting.Origin}/> : null}
                        </h5>

                        {stopsComponent}

                        <h5>
                            {segmentEnding.Destination && segmentEnding.Destination.ArrTime ? <>
                                {displayDate(segmentEnding.Destination.ArrTime)}
                            </> : null}

                            {segmentEnding.Destination ?
                                <FlightAddress data={segmentEnding.Destination}/> : null}


                        </h5>

                    </div>


                </li>

            </ul>
        </>
    )
}
export {OverseasWithStopComponents, OverseasNonStopComponents}
